import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

/**
 * A custom hook that dispatches the provided Redux action creator functions once the
 * component is mounted
 *
 * This hook can be used to trigger data fetching or other setup actions, in place of
 * calling dispatch inside a `useEffect` hook.
 *
 * @function useDispatchOnMount
 * @param {...Function} dispatchFunctions - One or more Redux action creator functions.
 *  Each function will be dispatched when the component mounts.
 *
 * @example
 * import { useDispatchOnMount } from 'js/includes/common/hooks/useDispatchOnMount';
 * import { fetchUserData, fetchSettings } from './actions';
 *
 * const Foo = () => {
 *   useDispatchOnMount(() => fetchUserData('userId'), fetchSettings);
 * // ... the rest of your component
 *
 * // When the component mounts, `() => fetchUserData('userId')` and `fetchSettings()` will be dispatched automatically.
 */
export const useDispatchOnMount = (...dispatchFunctions) => {
  const dispatch = useDispatch()
  const dispatchFunctionsRef = useRef(dispatchFunctions)

  useEffect(() => {
    dispatchFunctionsRef.current.forEach(fn => {
      if (typeof fn !== "function") {
        throw new Error(`useDispatchOnMount: Invalid argument, expected function but received ${typeof fn}`)
      }
      dispatch(fn())
    })
  }, [dispatch])
}
